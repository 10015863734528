
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed, watchEffect, onMounted } from 'vue';
import { Input, Select, Image } from '@/components/input-elements';
import { useStore } from 'vuex';
import { CatalogAddFormData } from '../interfaces/index';
import { Apollo, Notify } from '@/core/services';
import { GET_CATEGORIES, GET_CATEGORIES_DROPDOWN_LIST } from '../graphql/Queries';
import { CREATE_CATEGORY } from '../graphql/Mutations';
import { Actions } from '@/store/enums/StoreEnums';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'new-category-modal',
    components: {
        Input,
        Select,
        Image,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const loading = ref(false);
        const locales = ref([]) as Record<any, any>;
        const modal: any = ref();
        const tabsValue = ref('en');
        const store = useStore();
        const disabled = ref(false);
        const set_sites = ref([]) as Record<any, any>;
        const addCategoryForm = ref<null | HTMLFormElement>(null);
        const categories = ref([]) as Record<any, any>;
        const previewImage = ref([]) as Record<any, any>;
        const i18n = useI18n();
        const editorForm = ref<null | HTMLFormElement>(null);
        const editorMetaForm = ref<null | HTMLFormElement>(null);
        const validate = ref(false);

        const addCategoryData = ref<CatalogAddFormData>({
            locale: 'en',
            name: '',
            sites: [],
            description: '',
            metaDescription: '',
            metaTitle: '',
            parent_id: null,
            status: false,
            show_on_shop: true
        });

        //Load all site
        const get_sites = computed(() => {
            return store.getters.getAllSites.data.sites;
        });

        const categoriesList = ref([]);

        //Load all categories
        const get_categories = () => {
            Apollo.watchQuery({
                query: GET_CATEGORIES_DROPDOWN_LIST,
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-first'
            }).subscribe(({ data }) => {
                categoriesList.value = [];
                categoriesList.value = data.categories.data;
            });
        };

        // any changing in site
        watchEffect(() => {
            set_sites.value = [];
            get_sites.value.forEach(element => {
                set_sites.value.push({
                    value: element.id,
                    label: element.name
                });
            });

            categories.value = [];
            categoriesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '';
                if (JSON.parse(element.name)[`${addCategoryData.value.locale}`] == undefined) {
                    defaultLocale = 'en';
                } else {
                    defaultLocale = addCategoryData.value.locale;
                }
                categories.value.push({
                    value: element.id,
                    label: JSON.parse(element.name)[`${defaultLocale}`]?.name
                });
            });
        });

        // On change image
        const handleChange = async (event: Record<any, any>) => {
            loading.value = true;
            const media = event.target.files;
            const response = await store.dispatch(Actions.CREATE_MEDIA, media);
            const images = JSON.parse(response);
            images.forEach(element => {
                previewImage.value.push({ src: element.asset_path, id: element.id });
            });
            loading.value = false;
        };

        // Remove Media Function
        const removeMedia = (index: number, media_id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loading.value = true;
                    const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
                    if (resp == 'true') {
                        previewImage.value.splice(index, 1);
                        Notify.success(i18n.t('message.MEDIA_REMOVED_SUCCESSFULLY'));
                    }
                    loading.value = false;
                })
                .catch(() => {
                    loading.value = false;
                })
                .catch(() => {
                    loading.value = true;
                    // loader.$loading().close();
                });
        };

        // Show Media
        const showMedia = async (num: number) => {
            const formData = {
                previewImage: previewImage.value,
                visible: true,
                index: num
            };
            await store.dispatch(Actions.TRIGGER_ACTION, { formData: formData, type: 'imageProps' });
        };

        // Store name in Danish and English
        const tempValues = ref({}) as Record<any, any>;

        // Store description in Danish and English
        const tempValuesDescription = ref({}) as Record<any, any>;

        // Store meta description in Danish and English
        const tempValuesMetaDescription = ref({}) as Record<any, any>;

        const tempValuesMetaTitle = ref({}) as Record<any, any>;

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                tempValues.value[element.locale] = { name: '' };
                tempValuesDescription.value[element.locale] = { name: '' };
                tempValuesMetaDescription.value[element.locale] = { name: '' };
                tempValuesMetaTitle.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        // On change input value
        const changeLang = (e: string) => {
            tabsValue.value = e;
            if (addCategoryData.value.description == '' || addCategoryData.value.description === '<p><br></p>') {
                validate.value = false;
            }
            addCategoryData.value.name = tempValues.value[e].name;
            addCategoryData.value.description = tempValuesDescription.value[e].name;
            addCategoryData.value.metaDescription = tempValuesMetaDescription.value[e].name;
            addCategoryData.value.metaTitle = tempValuesMetaTitle.value[e].name;
            editorForm.value?.setHTML(addCategoryData.value.description);
            editorMetaForm.value?.setHTML(addCategoryData.value.metaDescription);
        };

        // update on change value input
        const updateValue = (e: string) => {
            tempValues.value[addCategoryData.value.locale].name = e;
        };

        // tab link press to ge locale
        const tabClickHandler = (tab: Record<any, any>) => {
            addCategoryData.value.description = tempValuesDescription.value[tab.props.name].name;
            addCategoryData.value.metaDescription = tempValuesMetaDescription.value[tab.props.name].name;
            addCategoryData.value.metaTitle = tempValuesMetaTitle.value[tab.props.name].name;
            addCategoryData.value.locale = tab.props.name;
            addCategoryData.value.name = tempValues.value[tab.props.name].name;
            editorForm.value?.setHTML(addCategoryData.value.description);
            editorMetaForm.value?.setHTML(addCategoryData.value.metaDescription);
        };

        // Update value on change description
        const updateValueDescription = (e: string) => {
            tempValuesDescription.value[addCategoryData.value.locale].name = e;
        };

        // Update value on change meta description
        const updateValueMetaDescription = (e: string) => {
            tempValuesMetaDescription.value[addCategoryData.value.locale].name = e;
        };

        // Update value on change meta title
        const updateValueMetaTitle = (e: string) => {
            tempValuesMetaTitle.value[addCategoryData.value.locale].name = e;
        };

        const customValidations = () => {
            if ((addCategoryData.value.description == '' && validate.value == true) || (addCategoryData.value.description === '<p><br></p>' && validate.value == true)) {
                Notify.error(`${i18n.t('message.CATEGORY')} ${i18n.t('message.DESCRIPTION')} ${i18n.t('message.IS_REQUIRED')}`);
                return false;
            }

            return true;
        };

        // Form submit Handler
        const submitUserForm = () => {
            for (const i in tempValuesDescription.value) {
                if (tempValuesDescription.value[i].name == '') {
                    tempValuesDescription.value[i].name = addCategoryData.value.description;
                }
            }
            for (const i in tempValuesMetaDescription.value) {
                if (tempValuesMetaDescription.value[i].name == '') {
                    tempValuesMetaDescription.value[i].name = addCategoryData.value.metaDescription;
                }
            }

            for (const i in tempValuesMetaTitle.value) {
                if (tempValuesMetaTitle.value[i].name == '') {
                    tempValuesMetaTitle.value[i].name = addCategoryData.value.metaTitle;
                }
            }

            for (const i in tempValues.value) {
                if (tempValues.value[i].name == '') {
                    tempValues.value[i].name = addCategoryData.value.name;
                }
            }

            // if (previewImage.value.length == 0) {
            // 	Notify.error(`${i18n.t("message.IMAGE")} ${i18n.t("message.IS_REQUIRED")}`);
            // 	return;
            // }

            validate.value = true;
            addCategoryForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const validator = customValidations();
                    if (validator == true) {
                        const formData = {
                            name: JSON.stringify(tempValues.value),
                            description: JSON.stringify(tempValuesDescription.value),
                            meta_description: JSON.stringify(tempValuesMetaDescription.value),
                            meta_title: JSON.stringify(tempValuesMetaTitle.value),
                            parent_id: addCategoryData.value.parent_id == null || addCategoryData.value.parent_id == '' ? 0 : addCategoryData.value.parent_id,
                            sites: addCategoryData.value.sites,
                            image: previewImage.value.length != 0 ? previewImage.value[0].id : null,
                            status: addCategoryData.value.status,
                            show_on_shop: addCategoryData.value.show_on_shop
                        };

                        loading.value = true;
                        await Apollo.mutate({
                            mutation: CREATE_CATEGORY,
                            variables: {
                                input: formData
                            },
                            update: (store, { data: { create_category } }) => {
                                const read_data = store.readQuery({
                                    query: GET_CATEGORIES,
                                    variables: {
                                        page: 1,
                                        limit: 10
                                    }
                                }) as Record<any, any>;
                                store.writeQuery({
                                    query: GET_CATEGORIES,
                                    variables: {
                                        page: 1,
                                        limit: 10
                                    },
                                    data: {
                                        categories: {
                                            ...read_data?.categories,
                                            data: [create_category, ...read_data?.categories?.data]
                                        }
                                    }
                                });

                                const read_dropdown_list_data = store.readQuery({
                                    query: GET_CATEGORIES_DROPDOWN_LIST
                                }) as Record<any, any>;
                                store.writeQuery({
                                    query: GET_CATEGORIES_DROPDOWN_LIST,
                                    data: {
                                        categories: {
                                            ...read_dropdown_list_data?.categories,
                                            data: [create_category, ...read_dropdown_list_data?.categories?.data]
                                        }
                                    }
                                });

                                modal.value.hide();
                                loading.value = false;
                                Notify.success(`${i18n.t('message.RECORD_ADDED_SUCCESSFULLY')}`);
                            }
                        }).catch(() => {
                            loading.value = false;
                        });
                    } else {
                        loading.value = false;
                    }
                }
            });
        };

        const resetForm = () => {
            categories.value = [];
            validate.value = false;
            get_categories();
            set_sites.value = [];
            previewImage.value = [];
            emitter.emit('clearInput');
            addCategoryData.value.sites = [];
            tabsValue.value = 'en';
            // empty json fields data
            for (const i of Object.getOwnPropertyNames(tempValues.value)) {
                tempValues.value[i].name = '';
                tempValuesDescription.value[i].name = '';
                tempValuesMetaDescription.value[i].name = '';
                tempValuesMetaTitle.value[i].name = '';
            }
            editorForm.value?.setHTML('');
        };

        onMounted(() => {
            resetForm();
        });

        // Emitter for open modal
        emitter.on('addCategoryAction', () => {
            resetForm();
            validate.value = false;
            addCategoryForm.value?.resetFields();
            modal.value = new Modal(document.getElementById('modal_add_category'));
            modal.value.show();
        });

        return {
            get_sites,
            set_sites,
            locales,
            addCategoryData,
            loading,
            tabsValue,
            disabled,
            categories,
            addCategoryForm,
            previewImage,
            editorForm,
            editorMetaForm,
            validate,
            showMedia,
            removeMedia,
            handleChange,
            tabClickHandler,
            updateValueDescription,
            updateValueMetaDescription,
            changeLang,
            updateValue,
            submitUserForm,
            updateValueMetaTitle
        };
    }
});
